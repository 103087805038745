import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

import bourse2018 from "../img/bourse2018.png";
import bourse2019 from "../img/bourse2019.jpg";
import bourse2020 from "../img/bourse2020.png";
import bourse2021 from "../img/bourse2021.png";
import bourse2022 from "../img/bourse2022.jpg";
import "../css/Temoignages.css";

const Temoignages = () => (
    <div id='temoignages' className='section dark'>
        <div className='content'>
            <h1 className='sectiontitle'>
                <span className='red'>P</span>récédemment
            </h1>
            <div className='temoignage'>
                <div className='grid-m-12 grid-l-7 grid-xl-8 temoignage-content'>
                    <h1 className='temoignage-title'>
                        <span className='red'>E</span>dition 2017
                    </h1>
                    <h3 className='temoignage-winner-name'>La plateforme Agreed</h3>
                    <p>
                        En 2017, nous étions en pleine période électorale pour les présidentielles.
                        <br />
                        Nous avons ainsi choisi un sujet de{" "}
                        <b>développement d'une plateforme web pour permettre le vote d'un groupe d'ami·e·s sur différents topics​</b>.<br />
                    </p>
                    <h3 className='temoignage-winner-name'>Thomas Ville, ESIEA</h3>
                    <p>
                        Thomas a remporté le concours pour plusieurs raisons : son concept était <b>le plus innovant</b>, son rendu très{" "}
                        <b>soigné</b> et il a choisi de travailler avec des <b>technos qui lui étaient inconnues</b>. <br />
                        Belle séance de tournage artisanal pour son pitch peu après le cocktail de remise des prix !<br />
                        Et bien sûr dédicace à{" "}
                        <a href='https://soundcloud.com/moustachemachine' target='_blank' rel='noopener noreferrer'>
                            Moustache Machine
                        </a>{" "}
                        pour la musique.
                    </p>
                    <p>
                        Sujet 2017 : <a className="pointer blue" href={process.env.PUBLIC_URL + "/Sujet_Bourse_Coddity_2017.pdf"} target='_blank'><FontAwesomeIcon icon={faFilePdf}/></a>
                    </p>
                </div>
                <div className='grid-m-12 grid-l-5 grid-xl-4 temoignage-img'>
                    <iframe
                        title='pitch lauréat bourse coddity 2017'
                        width='560'
                        height='280'
                        src='https://www.youtube.com/embed/gdsr5Qq83xM'
                        frameBorder='0'
                        allowFullScreen
                    ></iframe>
                </div>
            </div>



            <div className='temoignage reversed'>
                <div className='grid-m-12 grid-l-7 grid-xl-8 temoignage-content'>
                    <h1 className='temoignage-title'>
                        <span className='red'>E</span>dition 2018
                    </h1>
                    <h3 className='temoignage-winner-name'>Une alternative à LinkedIn pour les lycéen·ne·s ?</h3>
                    <p>
                        Avec l'inauguration de <b>Parcoursup</b>, on s'est replongé dans cette période de fin de lycée, où nous n'avions peu
                        ou pas d'expérience professionnelle. Le sujet que nous avons choisi était le développement d'un{" "}
                        <b>outil web pour permettre aux lycéen·ne·s et jeunes étudiant·e·s de mettre en avant leurs qualités</b>, au-delà des
                        expériences professionnelles ; utile pour candidater à un job d'été ou à une école spécifique.
                    </p>
                    <h3 className='temoignage-winner-name'>Nathan Seva et Alexandre Plateau, Polytech Orléans</h3>
                    <p>
                        Malgré une grille d'évaluation fixée au préalable, nous n'avons pas réussi à départager Nathan et Alexandre. Nous les
                        avons tous deux désignés lauréats pour leur maturité : travail abouti certes, mais aussi <b>documentation fournie</b>,{" "}
                        <b>choix techniques audacieux, cohérents et justifiés</b>, <b>auto-critique pertinente</b>.<br />
                        Par ailleurs, le prix du jury revient à Léo Fargeas (IMT Atlantique) pour son concept séduisant de projets
                        interconnectés.
                    </p>
                    <p>
                        Sujet 2018 : <a className="pointer blue" href={process.env.PUBLIC_URL + "/Sujet_Bourse_Coddity_2018.pdf"} target='_blank'><FontAwesomeIcon icon={faFilePdf}/></a>
                    </p>
                </div>
                <div className='grid-m-12 grid-l-5 grid-xl-4 temoignage-img'>
                    <img src={bourse2018} alt='bourse coddity 2018' className='temoignage-photo' />
                </div>
            </div>



            <div className='temoignage'>
                <div className='grid-m-12 grid-l-7 grid-xl-8 temoignage-content'>
                    <h1 className='temoignage-title'>
                        <span className='red'>E</span>dition 2019
                    </h1>
                    <h3 className='temoignage-winner-name'>La place des femmes dans la tech</h3>
                    <p>
                    Le constat n'est pas difficile à réaliser : <b>les femmes sont largement minoritaires </b> dans les métiers de la tech. Quelles sont les raisons de ce déséquilibre ? <br/>
                    Ainsi, nous avons réalisé <b>onze entretiens auprès de 8 femmes et 3 hommes</b>, évoluant à des postes dans le développement, le marketing, la data, le commercial, la sécurité et le journalisme. Nous avons abordé leur parcours, les difficultés rencontrées et <b>leur vision de l’équilibre hommes / femmes</b> dans leur métier.<br/>
                    Les candidat·e·s ont analysé les 20 pages de retranscription d'entretiens et <b>sélectionné une des problématiques abordées, à laquelle ils ont répondu au moyen d'un outil qu'ils ont développé.</b>
                    </p>
                    <h3 className='temoignage-winner-name'>Hessou PANASSIM, UTBM</h3>
                    <p>
                        Hessou souligne le <b>manque d’information</b> des jeunes femmes sur les métiers de la tech. Pour cela, elle propose
                        une plateforme d'échanges où les <b>Cadettes</b> curieuses <b>reçoivent</b> conseils et réponses à leurs questions.
                        Les <b>Aînées partagent témoignages</b> et retours d'expériences sur leur parcours et leur quotidien.
                    </p>
                    <p>
                        Sujet 2019 : <a className="pointer blue" href={process.env.PUBLIC_URL + "/Sujet_Bourse_Coddity_2019.pdf"} target='_blank'><FontAwesomeIcon icon={faFilePdf}/></a>
                    </p>
                </div>
                <div className='grid-m-12 grid-l-5 grid-xl-4 temoignage-img'>
                    <img src={bourse2019} alt='bourse coddity 2019' className='temoignage-photo' />
                </div>
            </div>



            <div className='temoignage reversed'>
                <div className='grid-m-12 grid-l-7 grid-xl-8 temoignage-content'>
                    <h1 className='temoignage-title'>
                        <span className='red'>E</span>dition 2020
                    </h1>
                    <h3 className='temoignage-winner-name'>L'informatique à la portée des plus jeunes</h3>
                    <p>
                        Nous avons demandé aux candidat·e·s de développer une <b>plateforme de découverte</b> d’une discipline de l’informatique pour <b>les enfants</b>.
                    </p>
                    <h3 className='temoignage-winner-name'>3 équipes lauréates pour trois projets aboutis</h3>
                    <ul>
                        <li>Tania Abitbol d'HETIC, avec sa plateforme de Quizz sur l'informatique <b>InfoQuiz</b>,</li>
                        <li>Pierre Bouillon et Raphaël Clarisse de Telecom Nancy et de l'Université de Bourgogne pour <b>InTechNet</b>, une plateforme de cours à destination des enseignant·e·s,</li>
                        <li>Gawein LE GOFF et Zacharia MESBAH de l’INSA Rouen avec leur app d'initiation au dev Python <b>Py4kids</b>.</li>
                    </ul>
                    <p>
                        Sujet 2020 : <a className="pointer blue" href={process.env.PUBLIC_URL + "/Sujet_Bourse_Coddity_2020.pdf"} target='_blank'><FontAwesomeIcon icon={faFilePdf}/></a>
                    </p>
                </div>
                <div className='grid-m-12 grid-l-5 grid-xl-4 temoignage-img'>
                    <img src={bourse2020} alt='bourse coddity 2020' className='temoignage-photo' />
                </div>
            </div>



            <div className='temoignage'>
                <div className='grid-m-12 grid-l-7 grid-xl-8 temoignage-content'>
                    <h1 className='temoignage-title'>
                        <span className='red'>E</span>dition 2021
                    </h1>
                    <h3 className='temoignage-winner-name'>Combattre les fake news</h3>
                    <p>
                        Nous avons demandé aux candidat·e·s de développer un <b>tribunal de l'information</b> afin de qualifier des news, données, rapports, 
                        et mettre en lumière les <b>fake news</b>.
                    </p>
                    <h3 className='temoignage-winner-name'>3 équipes lauréates</h3>
                    <ul>
                        <li>Charlène Gros, de l'IUT d'Orsay, et Morgane Tanet, de Sciences Po à l'UVSQ, avec <b>Facts are Facts</b>, un site qui favorise le débat et permet l'intervention de spécialistes des sujets,</li>
                        <li>Anicet Nougaret, en 2ème année à l'IUT de Paris, qui a développé <b>3W Sherlock</b>, un jeu permettant de débattre sur un sujet selon un rôle : avocat ou contributeur de la défense ou de l'attaque, et juge,</li>
                        <li>Pierre Bouillon (seconde fois vainqueur !) et Antoine Jesus de Télécom Nancy et <b>Shepherd</b>, un plugin firefox et un site permettant d'établir par la communauté une note de fiabilité de medias,</li>
                    </ul>
                    <p>
                        Sujet 2021 : <a className="pointer blue" href={process.env.PUBLIC_URL + "/Sujet_Bourse_Coddity_2021.pdf"} target='_blank'><FontAwesomeIcon icon={faFilePdf}/></a>
                    </p>
                </div>
                <div className='grid-m-12 grid-l-5 grid-xl-4 temoignage-img'>
                    <img src={bourse2021} alt='bourse coddity 2021' className='temoignage-photo' />
                </div>
            </div>



            <div className='temoignage reversed'>
                <div className='grid-m-12 grid-l-7 grid-xl-8 temoignage-content'>
                    <h1 className='temoignage-title'>
                        <span className='red'>E</span>dition 2022
                    </h1>
                    <h3 className='temoignage-winner-name'>Vulgarisation du rapport du GIEC</h3>
                    <p>
                        <span className='red'>L</span>e <b>GIEC</b> a publié son <b>6ème rapport</b> début 2022 sur l’état
                        des connaissances de l’impact humain sur le <b>réchauffement climatique</b>.
                        Pour beaucoup, ces données sont <b>floues et complexes à appréhender</b>.
                        <br /><br />
                        Les participant·e·s ont eu <b>deux mois</b> pour développer une application qui permet de
                        rendre le contenu de ce 6ème rapport <b>accessible, attractif, plus facile à comprendre par tous·tes</b>.
                    </p>
                    <h3 className='temoignage-winner-name'>3 équipes lauréates pour trois projets aboutis</h3>
                    <ul>
                        <li>Matéo Ducastel de l'ENSICAEN, avec <b>Cliday</b>, une app mobile de quizz quotidien permettant de renforcer ses connaissances sur l'environnement et le climat.</li>
                        <li>Fabien Goubin de Polytech Lyon, pour son <b>jeu mobile</b> "dont vous êtes le héros" : une crise environnementale survient et vos actions ont des conséquences sur la planète.</li>
                        <li>Sami Lafrance & Elise Echasseriau, d'ESD Bordeaux, qui ont créé <b>Warmd</b>, un quizz sur le rapport du GIEC accompagné d'un mode permettant de défier ses amis.</li>
                    </ul>
                    <p>
                        Sujet 2022 : <a className="pointer blue" href={process.env.PUBLIC_URL + "/Sujet_Bourse_Coddity_2022.pdf"} target='_blank'><FontAwesomeIcon icon={faFilePdf}/></a>
                    </p>
                </div>
                <div className='grid-m-12 grid-l-5 grid-xl-4 temoignage-img'>
                    <img src={bourse2022} alt='bourse coddity 2022' className='temoignage-photo' />
                </div>
            </div>
        </div>
    </div>
    );

export default Temoignages;