import React from "react";
import Scroll from 'react-scroll';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

import "../css/Home.css";

import mainLogo2022Svg from "../img/main-logo-2022.svg";

const Home = () => (
    <div className='section' id='home'>
        <div className='content'>
            <div className='row'>
                <div className='grid-s-12 grid-m-12'>
                    <img className='logohome' alt='LA BOURSE CODDITY' src={mainLogo2022Svg} />
                </div>
            </div>

            <h1 className='baseline'>
                <Scroll.Link className="green pointer" activeClass="selected" to="principe" spy={true} smooth={true} duration={500}>[6ème édition terminée]</Scroll.Link><br />
                <a className="pointer blue" href={process.env.PUBLIC_URL + "/Sujet_Bourse_Coddity_2022.pdf"} target='_blank'>(Re)voir le sujet <FontAwesomeIcon icon={faFilePdf}/></a><br />
                Petite pause en 2023, rendez-vous en janvier 2024 pour la 7ème édition
            </h1>
        </div>
    </div>
);

export default Home;
