import React, { useState, useEffect } from 'react';
import Scroll from 'react-scroll';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import topLeftLogoSvg from '../img/top-logo-lightning-left-2022.svg';

const Header = () => {

  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const [showLogo, setShowLogo] = useState(false);

  // ComponentDidMount
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // ComponentWillUnmount
    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    }
  });
  
  const handleScroll = (e) => {
    setShowLogo(window.scrollY >= 320);
  }

  const closeNavMenu = () => {
    setNavMenuOpen(false);
  }

  const handleClickNavMenu = () => {
    setNavMenuOpen(!navMenuOpen);
  }

  return (
    <header className={navMenuOpen ? "is-open" : ""}>
      {/*<!-- Petit logo coddity si navigation sur mobile ou petite fenêtre -->*/}  
      <Scroll.Link className={`logo-top-mobile ${showLogo || navMenuOpen ? 'pointer visible' : 'hidden'}`} to="home" onClick={closeNavMenu} spy={true} smooth={true} duration={500} ></Scroll.Link>
      {/*<!-- Mobile Burger button -->*/}
      <div className={navMenuOpen ? "nav-button is-open" : "nav-button"} onClick={handleClickNavMenu}>
        <span></span>
      </div>
      {/*<!-- Mobile navigation menu -->*/}
      <div className={navMenuOpen ? "nav-menu-mobile is-open" : "nav-menu-mobile"}>
        <ul>
          <li className="navbutton pointer">
            <Scroll.Link activeClass="selected" to="home" onClick={closeNavMenu} spy={true} smooth={true} duration={500} >Accueil</Scroll.Link>
          </li>
          <li className="navbutton pointer">
            <Scroll.Link activeClass="selected" to="principe" onClick={closeNavMenu} spy={true} smooth={true} duration={500} >Principe</Scroll.Link>
          </li>
          <li className="navbutton pointer">
            <Scroll.Link activeClass="selected" to="temoignages" onClick={closeNavMenu} spy={true} smooth={true} duration={500} >Précédemment</Scroll.Link>
          </li>
          <li className="navbutton pointer">
            <Scroll.Link activeClass="selected" to="inscription" onClick={closeNavMenu} spy={true} smooth={true} duration={500} >Inscription</Scroll.Link>
          </li>
          <li className="navbutton pointer">
              <a href="https://coddity.com/" target="_blank" rel="noopener noreferrer" className="blue">
                Coddity.com <FontAwesomeIcon icon={faExternalLinkAlt} size="xs"/>
              </a>
          </li>
        </ul>
        <ul className={navMenuOpen ? "socialnetwork grid-s-12" : ""} style={{display:'flex',justifyContent:'space-around',padding:"0 30px",boxSizing:'border-box'}}>
          <li><a className="pointer" href="https://www.facebook.com/coddity"><FontAwesomeIcon icon={faFacebook}/> </a></li>
          <li><a className="pointer" href="https://twitter.com/coddity"><FontAwesomeIcon icon={faTwitter}/> </a></li>
          <li><a className="pointer" href="https://www.linkedin.com/company/coddity"><FontAwesomeIcon icon={faLinkedin}/> </a></li>
          <li><a className="pointer" href="https://www.instagram.com/instantscoddity/"><FontAwesomeIcon icon={faInstagram}/> </a></li>
          <li><a className="pointer" href="mailto:contact@coddity.com"><FontAwesomeIcon icon={faEnvelope}/> </a></li>
        </ul>
      </div>
      
      {/*<!-- Logo en haut à gauche de l'écran en Desktop -->*/}
      <Scroll.Link id="toplogolink" className={`${showLogo ? 'pointer visible' : 'hidden'}`} to="home" spy={true} smooth={true} duration={500} >
        <div id="toplogo" className="grid-s-2">
          <img src={topLeftLogoSvg} alt="La Bourse Coddity" />

        </div>
      </Scroll.Link>
      {/*<!-- Navbar menu -->*/}
      <div className="navbarmenu grid-s-9 grid-xl-offset-6 grid-xxl-offset-7 grid-l-offset-5 grid-m-offset-4 grid-s-offset-2">
        <ul>
          <li className="navbutton"><Scroll.Link activeClass="selected" to="home" spy={true} smooth={true} duration={500} >Accueil</Scroll.Link></li>
          <li className="navbutton"><Scroll.Link activeClass="selected" to="principe" spy={true} smooth={true} duration={500} >Principe</Scroll.Link></li>
          <li className="navbutton"><Scroll.Link activeClass="selected" to="temoignages" spy={true} smooth={true} duration={500} >Précédemment</Scroll.Link></li>
          <li className="navbutton"><Scroll.Link activeClass="selected" to="inscription" spy={true} smooth={true} duration={500} >Inscription</Scroll.Link></li>
          <li className="navbutton">
            <a href = "https://coddity.com/" target="_blank" rel="noopener noreferrer">
              <span className="blue">Coddity.com <FontAwesomeIcon icon={faExternalLinkAlt} size="xs"/></span>
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
}
    
export default Header;          