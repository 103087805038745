import React from 'react';
import Home from './Home.js';
import Principe from './Principe.js';
import Temoignages from './Temoignages.js';
// import InscriptionForm from './InscriptionForm.js';
import InscriptionClosed from './InscriptionClosed.js';
// import InscriptionWait from './InscriptionWait.js';


const Sections = () => (
  <div id="ui-view">
    <Home />
    <Principe />
    <Temoignages />
    { /* <InscriptionForm /> */}
    <InscriptionClosed />
    { /* <InscriptionWait/> */}
  </div>
);
  
export default Sections;          