import React from 'react';

import '../css/inscriptionForm.css';

const InscriptionForm = () => (
    <div id="inscription" className="section">
        <div className="content">
            <h1 className="sectiontitle"><span className="red">I</span>nscriptions closes</h1>
            <div className="block-content">
                <p className="center">
                    Bravo aux équipes lauréates de cette 6ème édition.<br />
                    Petite pause en 2023, rendez-vous en janvier 2024 pour l'annonce de la 7ème édition ! Spoiler : le fonctionnement évoluera !<br /> <br />
                </p>
            </div>
        </div>
    </div>
);

export default InscriptionForm;