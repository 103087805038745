import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";

const Footer = () => (
  <div>
    <footer>
    <span className="grey footer">
      {new Date().getFullYear()} - <a href="https://coddity.com" target="_blank" rel="noopener noreferrer">
        <span className="red">C</span>oddity</a> - 37bis rue de Montreuil, 75011 Paris
        - 
        L'Escalier, 87400 Saint-Léonard-de-Noblat
      - 
      Crédits visuels : <a href="https://reshot.com" target="_blank" rel="noopener noreferrer">Reshot</a>
    </span>
    
    <ul className="socialnetwork">
      <li><a className="pointer" href="https://www.facebook.com/coddity"><FontAwesomeIcon icon={faFacebook}/> </a></li>
      <li><a className="pointer" href="https://twitter.com/coddity"><FontAwesomeIcon icon={faTwitter}/> </a></li>
      <li><a className="pointer" href="https://www.linkedin.com/company/coddity"><FontAwesomeIcon icon={faLinkedin}/> </a></li>
      <li><a className="pointer" href="https://www.instagram.com/instantscoddity/"><FontAwesomeIcon icon={faInstagram}/> </a></li>
      <li><a className="pointer" href="mailto:bourse@coddity.com"><FontAwesomeIcon icon={faEnvelope}/> </a></li>
    </ul>
  </footer>
  </div>
);
    
export default Footer;          
