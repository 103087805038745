import React from "react";
import Scroll from 'react-scroll';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import teamImg from "../img/team.png";
import workImg from "../img/work.png";
import planningImg from "../img/planning.png";
import booksImg from "../img/books.png";

import "../css/Temoignages.css";


const Principe = () => (
    <div id='principe' className='section dark'>
        {/* dark class is 1 on 2 to distinguish 2 sections
        scroll directive is important to let the scrolling work*/}
        <div className='content'>
            <h1 className='sectiontitle'>
                <span className='red'>P</span>rincipe
            </h1>


            <div className='temoignage'>
                <div className='grid-m-12 grid-l-7 grid-xl-8 temoignage-content'>
                    <h1 className='temoignage-title'>
                        <span className='red'>I</span>nscriptions
                    </h1>
                    <p>
                        <span className='red'>L</span>a Bourse est un concours de développement ouvert
                            aux <b>étudiant·e·s en informatique</b> inscrit·e·s dans un établissement
                            d'enseignement supérieur français. Relevez le défi en <b>solo ou en équipe de 2 ou 3 personnes</b> !
                    </p>
                    <h3 className='temoignage-winner-name'><span className='red'>T</span>iming</h3>
                    <p>
                        <Scroll.Link className="pointer" activeClass="selected" to="inscription" spy={true} smooth={true} duration={500}>Inscriptions jusqu'au <b>28 février 2022</b></Scroll.Link><br />
                        Sujet dévoilé le <b>1er mars 2022</b> <a style={{color: "#7289da"}} href="https://discord.gg/3H869hW">sur Discord <FontAwesomeIcon icon={faDiscord}/></a> !&nbsp;
                        <a className="pointer blue" href={process.env.PUBLIC_URL + "/Sujet_Bourse_Coddity_2022.pdf"} target='_blank'>Voir le sujet <FontAwesomeIcon icon={faFilePdf}/></a><br />
                    </p>
                </div>
                <div className='grid-m-12 grid-l-5 grid-xl-4 temoignage-img'>
                    <img src={teamImg} alt='Inscriptions à la Bourse' />
                </div>
            </div>


            <div className='temoignage reversed'>
                <div className='grid-m-12 grid-l-7 grid-xl-8 temoignage-content'>
                    <h1 className='temoignage-title'>
                        <span className='red'>C</span>oncours
                    </h1>
                    <p>
                        <span className='red'>I</span>I y a quelques jours, le <b>GIEC</b> a publié son <b>6ème rapport</b> sur l’état
                        des connaissances de l’impact humain sur le <b>réchauffement climatique</b>.
                        Pour beaucoup, ces données sont <b>floues et complexes à appréhender</b>.
                        <br /><br />
                        Les participant·e·s à la Bourse ont <b>deux mois</b> pour développer une application qui permet de
                        rendre le contenu de ce 6ème rapport <b>accessible, attractif, plus facile à comprendre par tous·tes</b>.
                    </p>
                    <p>
                        Les meilleures réalisations seront retenues et les équipes invitées à
                        venir <b>les défendre / pitcher</b> devant le jury chez <span className='red'>C</span>oddity.
                    </p>
                    <h3 className='temoignage-winner-name'><span className='red'>T</span>iming</h3>
                    <p>
                        Sujet dévoilé le <b>1er mars 2022</b> <a style={{color: "#7289da"}} href="https://discord.gg/3H869hW">sur Discord <FontAwesomeIcon icon={faDiscord}/></a> !&nbsp;
                        <a className="pointer blue" href={process.env.PUBLIC_URL + "/Sujet_Bourse_Coddity_2022.pdf"} target='_blank'>Voir le sujet <FontAwesomeIcon icon={faFilePdf}/></a><br />
                        Rendu des projets le <b>30 avril 2022</b><br />
                        Pitchs en <b>mai et juin 2022</b>
                    </p>
                    
                    
                    {/* <a className="pointer blue" href={process.env.PUBLIC_URL + "/Sujet_Bourse_Coddity_2022.pdf"} target='_blank'>Lien vers le sujet <FontAwesomeIcon icon={faFilePdf}/></a>*/}

                </div>
                <div className='grid-m-12 grid-l-5 grid-xl-4 temoignage-img'>
                    <img src={workImg} alt='Contenu du concours de la Bourse' className='temoignage-photo' />
                </div>
            </div>


            <div className='temoignage'>
                <div className='grid-m-12 grid-l-7 grid-xl-8 temoignage-content'>
                    <h1 className='temoignage-title'>
                        <span className='red'>R</span>ésultats
                    </h1>
                    <p>
                        <span className='red'>L</span>e jury sélectionnera <b>3 projets / équipes</b> qui recevront chacun
                        une bourse de <b>1000€</b>, coup de pouce pour financer les études.
                    </p>
                    <h3 className='temoignage-winner-name'><span className='red'>T</span>iming</h3>
                    <p>
                        Résultats annoncés en <b>juillet 2022</b><br />
                        Apéro de remise des prix en <b>septembre 2022</b>
                    </p>
                </div>
                <div className='grid-m-12 grid-l-5 grid-xl-4 temoignage-img'>
                    <img src={planningImg} alt='Résultats de la Bourse' />
                </div>
            </div>


            <div className='temoignage reversed'>
                <div className='grid-m-12 grid-l-7 grid-xl-8 temoignage-content'>
                    <h1 className='temoignage-title'>
                        <span className='red'>R</span>èglement
                    </h1>
                    <p>
                        <span className='red'>L</span>es participant·e·s gardent <b>l'entière propriété</b> de leur projet.
                        Leurs données personnelles <b>ne sont pas réutilisées</b>.
                        Participer <b>n'engage en rien</b> à toute autre collaboration avec Coddity.
                    </p>
                    <h3 className='temoignage-winner-name'>
                        <a href={process.env.PUBLIC_URL + "/Reglement_Concours_Bourse_Coddity_2022.pdf"} target='_blank'>
                            <span className='red'>
                                Règlement de la 6ème édition&nbsp; 
                                <FontAwesomeIcon icon={faFilePdf}/>
                            </span>
                        </a>
                    </h3>

                </div>
                <div className='grid-m-12 grid-l-5 grid-xl-4 temoignage-img'>
                    <img src={booksImg} alt='Règlement, données personnelles et transparence de la Bourse' className='temoignage-photo' />
                </div>
            </div>

            {/*
            <div className="block-content">
                <div className="row">
                    <div className='grid-s-8 grid-s-offset-2 grid-m-8 grid-m-offset-2 grid-l-6 grid-l-offset-3 center'>
                        <img src={bourse2021} alt='flyer bourse coddity 2021' />
                        <p className="center">
                            <a className="pointer blue" href={process.env.PUBLIC_URL + "/Sujet_Bourse_Coddity_2021.pdf"} target='_blank'>
                                Sujet 2021 : Combattre les fake news grâce au Tribunal de l'information&nbsp;
                                <FontAwesomeIcon icon={faFilePdf}/>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            */}
        </div>
    </div>
);

export default Principe;
