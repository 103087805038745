import React from 'react';
import './css/App.css';
import './css/nav-menu.css';
import './css/Sections.css';
import './css/fonts.css';
import './css/form.css';
import './css/lightgrid.css';
import './css/font-awesome/css/font-awesome.min.css';
import Header from './components/Header.js';
import Sections from './components/Sections.js';
import Footer from './components/Footer.js';

const App = () => (
  <div className="App">
    <Header />
    <Sections />
    <Footer />
  </div>
);

export default App;
